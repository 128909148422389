import type {
  DispatchesInfo,
  DispatchInfo,
  DispatchState,
  DispatchStateByLocation,
  Operation,
} from '../types/businessTypes';
import { DispatchType } from 'root/types/businessTypes';
import { makeAutoObservable, toJS, configure, action, observable } from 'mobx';
import { context } from 'root/context/RootContext';

configure({ isolateGlobalState: true });

class _DispatchState {
  dispatchStateByLocation: DispatchStateByLocation = new Map<string, DispatchState>();
  operations: Operation[] = [];
  currentOperationId?: string;
  isLoading?: boolean = true;
  dispatchType?: DispatchType;

  constructor() {
    makeAutoObservable(this, {
      init: action,
      update: action,
      setCurrentOperationId: action,
      setOperations: action,
      currentOperationId: observable,
    });
  }

  get currentOperation() {
    return this.operations.find((operation) => operation.id === this.currentOperationId);
  }

  get locationId() {
    return this.currentOperation?.locationId ?? '';
  }

  get dispatchStateByCurrentLocation(): DispatchState {
    return this.dispatchStateByLocation?.get(this.locationId) ?? ({} as DispatchState);
  }

  get dispatchesInfo(): DispatchesInfo {
    return this.dispatchStateByCurrentLocation.dispatchesInfo ?? ({} as DispatchesInfo);
  }

  get dispatchInfo(): DispatchInfo {
    return toJS(this.dispatchesInfo?.[this.selectedDispatchType]) ?? {};
  }

  get selectedDispatchType(): DispatchType {
    return this.dispatchType ?? DispatchType.PICKUP;
  }

  init({
    dispatchStateByLocation,
    operations,
    operationId,
    isLoading,
    dispatchType,
  }: {
    dispatchStateByLocation: DispatchStateByLocation;
    operations: Operation[];
    operationId: string;
    isLoading?: boolean;
    dispatchType?: DispatchType;
  }) {
    this.dispatchStateByLocation = dispatchStateByLocation;
    this.isLoading = isLoading;
    this.currentOperationId = operationId;
    this.operations = operations;
    this.dispatchType = dispatchType ?? this.dispatchStateByCurrentLocation.selectedDispatchType;
    context.PersistDataService?.setDispatchState(dispatchStateByLocation);
  }

  update(dispatchType: DispatchType, dispatchInfo: DispatchInfo) {
    const currentDispatchInfo = this.dispatchStateByCurrentLocation.dispatchesInfo[dispatchType];
    this.dispatchesInfo[dispatchType] = {
      ...currentDispatchInfo,
      ...dispatchInfo,
    };
    this.dispatchType = dispatchType;
    context.PersistDataService?.setDispatchState(this.state);
  }

  setOperations(operations: Operation[]) {
    this.operations = operations;
  }

  setCurrentOperationId(operationId: string) {
    this.currentOperationId = operationId;
  }

  get configuredDispatchTypes() {
    return this.dispatchStateByCurrentLocation.configuredDispatchTypes ?? [];
  }

  get availableDispatchTypes() {
    return this.dispatchStateByCurrentLocation.availableDispatchTypes ?? [];
  }

  get hasAvailableDispatches() {
    return this.availableDispatchTypes.length > 0;
  }

  get hasConfiguredDispatches() {
    return this.configuredDispatchTypes.length > 0;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  getShippingDetails() {
    const dispatchInfo = this.dispatchInfo;
    const shouldHaveTimeRange = !dispatchInfo.selectedTimeSlot?.startsNow;
    const timeRange = shouldHaveTimeRange
      ? {
          start: dispatchInfo.selectedTimeSlot!.startTime.toUTC().valueOf(),
          end: dispatchInfo.selectedTimeSlot!.endTime.toUTC().valueOf(),
        }
      : undefined;
    return {
      address: toJS(dispatchInfo.address),
      dispatchType: toJS(this.selectedDispatchType),
      timeRange,
    };
  }

  get state(): DispatchStateByLocation {
    return toJS(this.dispatchStateByLocation);
  }

  isDispatchAvailable(dispatchType: DispatchType) {
    return this.availableDispatchTypes.includes(dispatchType);
  }

  isDispatchConfigured(dispatchType: DispatchType) {
    return this.configuredDispatchTypes.includes(dispatchType);
  }
}

export const dispatchState = new _DispatchState();
